export const checkIsPositif = (anotation) =>
  anotation.attributes["hasil"] === "Positif" ||
  anotation.attributes["Hasil"] === "Positif";

export const checkIsSameLocation = (location, anotation) =>
  anotation.attributes.kab === location.value ||
  anotation.attributes.Kabupaten === location.value;

export const formateDate = (date) => {
  let newDate = date;

  if (newDate) {
    const datearray = date.split("/");

    newDate = `${datearray[1]}/${datearray[0]}/${datearray[2]}`;
  }

  return newDate;
};

export const anotationDate = (anotation) =>
  new Date(
    formateDate(anotation?.attributes.tgl_jawab) ||
      formateDate(anotation?.attributes.Tanggal) ||
      anotation?.created_at ||
      "1/1/2020"
  );

export const compareDate = (first, next) => {
  const a = anotationDate(first).getTime();
  const b = anotationDate(next).getTime();

  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};
