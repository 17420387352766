import Select from "react-select";
// import AppContext from "../../context/AppContext";
// const { FilterDateContext } = AppContext;

export const dateEnum = {
  HARI_INI: "HARI_INI",
  MINGGU_INI: "MINGGU_INI",
  BULAN_INI: "BULAN_INI",
  TAHUN_INI: "TAHUN_INI",
  SEMUA: "SEMUA",
};
const now = new Date();

export const dateOptions = [
  { label: "Semua Waktu", value: dateEnum.SEMUA, mindate: null },
  {
    label: "Tahun Ini",
    value: dateEnum.TAHUN_INI,
    mindate: new Date(now.getFullYear(), 0, 1),
  },
  {
    label: "Bulan Ini",
    value: dateEnum.BULAN_INI,
    mindate: new Date(now.getFullYear(), now.getMonth(), 1),
  },
  {
    label: "Minggu Ini",
    value: dateEnum.MINGGU_INI,
    mindate: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - now.getDay()
    ),
  },
  {
    label: "Hari ini",
    value: dateEnum.HARI_INI,
    mindate: new Date(now.setHours(0, 0, 0, 0)),
  },
];

const DateFilter = ({ setFilterDate }) => {
  // const { filterDate, setFilterDate } = useContext(FilterDateContext);
  return (
    <Select
      loadingMessage="Loading..."
      defaultValue={dateOptions[0]}
      options={dateOptions}
      isSearchable={false}
      onChange={(e) => {
        setFilterDate(e.value);
      }}
    />
  );
};

export default DateFilter;
