import { createContext } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import {
  anotationDate,
  checkIsPositif,
  checkIsSameLocation,
} from "../service/helper";
import { dateEnum, dateOptions } from "../components/filters/DateFilter";

function AppContext() {
  const AnotationContext = createContext();
  const FilterLocationContext = createContext();
  const FilterDateContext = createContext();
  const GroupContext = createContext();
  const ModalContext = createContext();

  const AnotationsProvider = ({ children }) => {
    const [anotations, setAnotations] = useState([]);
    const [anotationsFiltered, setAnotationsFiltered] = useState([]);
    const { groupSelected } = useContext(GroupContext);
    const { filterLocations } = useContext(FilterLocationContext);
    const { filterDate } = useContext(FilterDateContext);

    useEffect(() => {
      if (anotations.length) {
        setAnotationsFiltered(
          anotations.filter((anotation) => {
            const isIncludedFilterDate = () => {
              if (filterDate === dateEnum.SEMUA) return true;
              return (
                anotationDate(anotation) >=
                dateOptions.find((option) => option.value === filterDate)
                  ?.mindate
              );
            };

            if (
              !checkIsPositif(anotation) ||
              groupSelected !== anotation.group ||
              !isIncludedFilterDate()
            )
              return false;
            return (
              !filterLocations.length ||
              filterLocations.some((location) =>
                checkIsSameLocation(location, anotation)
              )
            );

            // return checkIsPositif(anotation) &&
            //   groupSelected === anotation.group
            //   ? !filterLocations.length ||
            //       filterLocations.some((location) =>
            //         checkIsSameLocation(location, anotation)
            //       )
            //   : false;
          })
        );
      }
    }, [anotations, filterLocations, groupSelected, filterDate]);

    return (
      <AnotationContext.Provider
        value={{ anotations, setAnotations, anotationsFiltered }}
      >
        {children}
      </AnotationContext.Provider>
    );
  };

  const FilterLocationsProvider = ({ children }) => {
    const [filterLocations, setFilterLocations] = useState([]);

    return (
      <FilterLocationContext.Provider
        value={{ filterLocations, setFilterLocations }}
      >
        {children}
      </FilterLocationContext.Provider>
    );
  };

  const FilterDateProvider = ({ children }) => {
    const [filterDate, setFilterDate] = useState(dateEnum.SEMUA);

    return (
      <FilterDateContext.Provider value={{ filterDate, setFilterDate }}>
        {children}
      </FilterDateContext.Provider>
    );
  };

  const GroupProvider = ({ children }) => {
    const [groups, setGroups] = useState([]);
    const [groupSelected, setGroupSelected] = useState();

    return (
      <GroupContext.Provider
        value={{
          groups,
          setGroups,
          groupSelected,
          setGroupSelected,
        }}
      >
        {children}
      </GroupContext.Provider>
    );
  };

  const ModalProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
      <ModalContext.Provider value={{ isModalOpen, setIsModalOpen }}>
        {children}
      </ModalContext.Provider>
    );
  };

  return {
    AnotationContext,
    AnotationsProvider,
    FilterLocationContext,
    FilterLocationsProvider,
    FilterDateContext,
    FilterDateProvider,
    GroupContext,
    GroupProvider,
    ModalContext,
    ModalProvider,
  };
}

export default AppContext();
