import { h } from "preact";
import Router, { Route } from "preact-router";
import AppContext from "./context/AppContext";
import Home from "./routes/home";

const {
  AnotationsProvider,
  FilterLocationsProvider,
  FilterDateProvider,
  GroupProvider,
  ModalProvider,
} = AppContext;

const App = () => (
  <div id="app">
    <FilterLocationsProvider>
      <FilterDateProvider>
        <GroupProvider>
          <AnotationsProvider>
            <ModalProvider>
              <Router>
                <Route path="/" component={Home} />
                {/* <NotFoundPage default /> */}
              </Router>
            </ModalProvider>
          </AnotationsProvider>
        </GroupProvider>
      </FilterDateProvider>
    </FilterLocationsProvider>
  </div>
);

export default App;
