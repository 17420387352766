// Must be the first import
import "preact/debug";

// Or if you just want the devtools bridge (~240B) without other
// debug code (useful for production sites)
import "preact/devtools";

import "./index.scss";

import App from "./app";

export default App;
